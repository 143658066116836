<template>
  <div>
    <el-dialog :visible.sync="visible" append-to-body width="66%" @close="closeDialog"
               :close-on-click-modal="false" :title="typeVal.title">

      <div class="nick_name_div">
        <p>
          简称：
        </p>
        <el-input
            v-model="nickName"
            placeholder="请输入简称,方便您检索"></el-input>
      </div>

      <div class="rsn_div">
        <div class="rsn_sub_div">
          <div class="title_div">
            <span class="title">发货人</span>
          </div>
          <AddManifestContactForm :type-val="type1" ref="contactForm1"
                                  :item="item.sendEntity"
                                  :country-list="countryList"></AddManifestContactForm>
        </div>
        <div class="rsn_sub_div">
          <div class="title_div">
            <span class="title">收货人</span>
            <span class="btn" @click="copy2Notice">同步到通知人</span>
          </div>
          <AddManifestContactForm :type-val="type2" ref="contactForm2"
                                  :item="item.reviceEntity"
                                  :country-list="countryList"></AddManifestContactForm>
        </div>
        <div class="rsn_sub_div">
          <div class="title_div">
            <span class="title">通知人</span>
          </div>
          <AddManifestContactForm
              :type-val="type3"
              ref="contactForm3"
              :item="item.noticeEntity"
              :country-list="countryList"></AddManifestContactForm>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
                <div style="margin:0 auto;">
                    <el-button @click="closeDialog">取 消</el-button>
                    <el-button type="primary" @click="checkData" :loading="saveLoading">确 定</el-button>
                </div>
            </span>
    </el-dialog>
  </div>
</template>
<script>
import AddManifestContactForm from "./AddManifestContactForm.vue";
import {getAllCountryCode, getRSNModuleDetail, insertRSNModule, updateRSNModule} from "@/api/shanghaiManifest";

export default {
  name: "AddManifestRSNDialog",
  components: {AddManifestContactForm},
  props: {
    typeVal: {
      type: Object,
      default: () => {
      }
    }
  },
  watch: {
    typeVal(newVal, oldVal) {
      this.form.type = newVal.code
    },
  },
  data() {
    return {
      type1: {"title": "发货人", code: 0},
      type2: {"title": "收货人", code: 1},
      type3: {"title": "通知人", code: 2},
      visible: false,
      saveLoading: false,
      countryList: [],
      nickName: '',
      item: {},
    }
  },
  methods: {
    init(item) {
      this.visible = true;
      console.log('item==', item)
      this.getAllCountryData();
      if (item) {
        this.$nextTick(() => {
          this.item = JSON.parse(JSON.stringify(item));
          this.getDetail();
        })
      }
    },
    getDetail() {
      if (this.item && this.item.id) {
        getRSNModuleDetail({id: this.item.id}).then((res) => {
              if (res.data.status) {
                this.item = res.data.data;
                this.nickName = this.item.nickName;
              }
            }
        ).finally(() => {

        })
      }
    },
    getAllCountryData() {
      getAllCountryCode().then(({data}) => {
        this.countryList = data.data.map(x => {
          return {
            id: x.id,
            label: x.code + '-' + x.name,
            value: x.code + '-' + x.name,
          }
        })
      })
    }
    ,
    closeDialog() {
      this.visible = false;
      this.nickName = '';
      this.$emit("close");
    }
    ,
    checkData() {
      let list = [];
      list.push(
          this.$refs.contactForm1.validate(),
          this.$refs.contactForm2.validate(),
          this.$refs.contactForm3.validate()
      );
      Promise.all(list)
          .then(() => {
            this.save()
          }).catch((err) => {
        this.$message({type: 'error', message: err})
      })
    },
    saveOrUpdate() {
      if (this.item && this.item.id) {
        this.update();
      } else {
        this.save();
      }
    },
    save() {
      let data = {
        nickName: this.nickName,
        id: this.item && this.item.id ? this.item.id : '',
        contacts: [
          this.$refs.contactForm1.form,
          this.$refs.contactForm2.form,
          this.$refs.contactForm3.form,
        ]
      }
      this.saveLoading = true;
      const saveOrUpdate = this.item.id ? updateRSNModule : insertRSNModule

      saveOrUpdate(data).then((res) => {
        if (res.data.status) {
          this.$message.success("保存成功");
          this.$emit('refresh');
          this.closeDialog();
        } else {
          this.$message.error("保存失败");
        }
      }).finally(() => {
        this.saveLoading = false;
      })
    }
    ,
    //将收货人信息同步到通知人
    copy2Notice() {
      const item = JSON.parse(JSON.stringify(this.$refs.contactForm2.form));
      item.type = 2;
      this.$refs.contactForm3.form = item;
    }
  },
  mounted() {

  }
}
</script>

<style scoped lang="stylus">
.rsn_div {
  display: flex;
  justify-content: space-between;
}

.rsn_sub_div {
  padding: 1rem;
  border: 1px solid #dcdcdc;

  .title_div {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid #dcdcdc;
  }

  .btn {
    cursor: pointer;
    color: var(--BLUE-409eff);
  }

  .title {
    text-align: center;
  }
}

.dialog-footer {
  text-align: center;
}

.nick_name_div {
  p {
    width: 5rem;
  }

  margin: 0 0 1rem 0;
  align-items: center;
  justify-content: center;
  display: flex;
  width: max-content;
}
</style>
